import SumsubWebSdk from "@sumsub/websdk-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SumsubAPI } from '../service/axiosInstances'

export default function SumsubSDK() {
  const [token, setAccessToken] = useState('');
  const { externalUserId, levelName } = useParams()
  const [message, setMessage] = useState('')

  const getAccessToken = async () => {
    try {

      const response = await SumsubAPI.post(`/createAccessToken/${externalUserId}/${levelName}`);
      setAccessToken(response.data.token);
    } catch (error) {
      console.error('Error fetching access token:', error);
    }
  };

  useEffect(() => {
    getAccessToken();
  }, []);

  const updateEvents = async (body) => {
    try {
      if (body.type === 'verify') {
        await SumsubAPI.post('/webhook', body)
      } else if (body.type === 'Consent Accepted') {
        await SumsubAPI.post('/webhook', body)
      }

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    console.log('env', process.env.REACT_APP_BK_BASE_URL)
    const date = new Date()
    if (message === 'idCheck.onInitialized' || message === 'idCheck.onStepInitiated') {
      updateEvents({ type: 'Consent Accepted', levelName: levelName, createdAt: date, externalUserId: externalUserId })
    }
    if (message === 'idCheck.onStepInitiated' || message === 'idCheck.onApplicantStatusChanged' || message === 'idCheck.onLivenessCompleted') {

      updateEvents({ type: 'verify', levelName: levelName, createdAt: date, externalUserId: externalUserId })

    }
  }, [message])


  return (<>
    {token ?
      <SumsubWebSdk
        accessToken={token}
        expirationHandler={(e) => console.log(e)}
        config={{
          lang: 'en'
        }}
        options={{}}
        onMessage={(msg) => setMessage(msg)}
        onError={(err) => console.log(err)}
      />
      : <p style={{ color: 'red', alignItems: 'center', textAlign: 'center' }}>Your Provided URL Invalid!</p>
    }
  </>
  );
}
