import React, { useEffect, useState } from 'react';
import SumsubWebSdk from '@sumsub/websdk-react';
import axios from 'axios';

import './App.css';
import { Route, BrowserRouter as Router, Routes, useParams } from 'react-router-dom';
import SumsubSDK from './components/sdk';

function App() {

  return (
          <>
            <Router>
                  <Routes>
                      <Route path="/sdk/:externalUserId/:levelName" element={<SumsubSDK />} />
                  </Routes>
            </Router>
          </>
  );
}

export default App;
